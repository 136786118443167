// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import {
	Link,
	Trans,
	useI18next,
	useTranslation,
} from "gatsby-plugin-react-i18next";
import { Parallax } from "react-scroll-parallax";
import { StaticImage } from "gatsby-plugin-image";

// style imports
import "./events.scss";

// svg imports

// components imports
import Layout from "../../../components/Layout/Layout.js";
import Seo from "../../../components/Seo/Seo.js";

// page
export default function IndexPage() {
	const { t } = useTranslation("privateEvents");
	const { language } = useI18next();

	const parallaxSettings = {
		speed: 20,
		easing: "easeInOut",
		className: "parallaxSection",
		onEnter: (element) => element.style,
	};

	const neededDetails = [
		t("date"),
		t("size of group"),
		t("property address"),
		t("menu request including dietary requirements & allergies"),
		t("kitchen and preparation possibilities"),
	];

	const servicesIncluded = [
		t("menu planning service by our Chef de Cuisine"),
		t("preparation & cooking with one of our professional Chefs"),
		t("setting the table"),
		t("chef & sufficient staff depending on size on party"),
		t("clearing & cleaning of the kitchen"),
	];

	const contactList = [
		{
			name: t("e-mail"),
			text: "info@bossaestepona.com",
			link: "mailto:porto@bossaestepona.com",
		},
		{
			name: t("phone"),
			text: (
				<>
					<a href="tel:+34649639676">+34 689 464 114</a>.
					<br />
					<a href="tel:+34693026559">+34 693 026 559</a>
				</>
			),
			link: "",
		},
		{
			name: t("instagram"),
			text: "@bossaestepona",
			link: "https://www.instagram.com/bossaestepona/",
		},
	];

	return (
		<Layout local="cafe">
			<Seo lang={language} title={t("Private events")} />
			<div className="bodyContainer">
				<section>
					<Parallax {...parallaxSettings}>
						<h2>sharing experience</h2>
						<h3>private chefs & catering</h3>
						<p>
							<Trans ns="privateEvents">
								these are our menu proposals for a unique bossa sharing
								experience
							</Trans>
							.<br />
							<Trans ns="privateEvents">
								we are always happy to adapt them or design bespoke menus for
								your dining experience with us
							</Trans>
							.
						</p>
						<br />

						<div className="eventsListContainer">
							<div className="eventsListFlex">
								<div className="bold">
									<Trans ns="privateEvents">to make a booking we need</Trans>:
								</div>
								{neededDetails.map((detail) => {
									return <div key={detail}>{detail}.</div>;
								})}
							</div>
							<br />
							<div className="eventsListFlex">
								<div className="bold">
									<Trans ns="privateEvents">services included</Trans>:
								</div>
								{servicesIncluded.map((detail) => {
									return <div key={detail}>{detail}.</div>;
								})}
							</div>
						</div>
					</Parallax>

					<StaticImage
						src={`../../../images/cafe/events/terrace.jpg`}
						alt={t("a sunny terrace with gorgeous views")}
						className={"landingBgImage"}
					/>
				</section>

				<section id="pricing">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans ns="privateEvents">pricing</Trans>.
						</h2>

						<div className="pricing">
							<div className="bold">
								<Trans ns="privateEvents">chef</Trans>.
							</div>
							<table>
								<tbody>
									<tr>
										<td>
											<Trans ns="privateEvents">breakfast</Trans>.
										</td>
										<td className="price">350.</td>
									</tr>
									<tr>
										<td colspan="2">
											<span className="light small">
												<Trans ns="privateEvents">up to 8 persons</Trans>.
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<Trans ns="privateEvents">lunch / dinner</Trans>.
										</td>
										<td className="price">400.</td>
									</tr>
									<tr>
										<td colspan="2">
											<span className="light small">
												<Trans ns="privateEvents">up to 6 persons</Trans>.
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="pricing">
							<div className="bold">
								<Trans ns="privateEvents">assistants</Trans>.
							</div>
							<span className="light small right">
								<Trans ns="privateEvents">
									8/10 or more guests will require the addition of an assistant
								</Trans>
								.
							</span>
							<table>
								<tbody>
									<tr>
										<td>
											<Trans ns="privateEvents">breakfast</Trans>.
										</td>
										<td className="price">350.</td>
									</tr>
									<tr>
										<td>
											<Trans ns="privateEvents">lunch / dinner</Trans>.
										</td>
										<td className="price">400.</td>
									</tr>
								</tbody>
							</table>
							<span className="light small right">
								<Trans ns="privateEvents">
									for groups of 18 or more please inquire
								</Trans>
								.
							</span>
						</div>

						<div className="pricing">
							<div className="bold">
								<Trans ns="privateEvents">add ons</Trans>.
							</div>
							<table>
								<tbody>
									<tr>
										<td>
											<Trans ns="privateEvents">waiter</Trans>.
											<br />
										</td>
										<td className="price">25/h.</td>
									</tr>
									<tr>
										<td>
											<Trans ns="privateEvents">cleaning</Trans>.
											<br />
										</td>
										<td className="price">20/h.</td>
									</tr>
									<tr>
										<td>
											<Trans ns="privateEvents">plates & cutlery</Trans>.
										</td>
										<td className="price">8/person.</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Parallax>

					<StaticImage
						src={`../../../images/cafe/events/food.jpg`}
						alt={t("a top view of some delicious looking food")}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="conditions">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans ns="privateEvents">payment conditions</Trans>.
						</h2>

						<div>
							<p>
								<Trans ns="privateEvents">50% to confirm booking.</Trans>
								<br />
								<Trans ns="privateEvents">
									if canceled 48h prior, 50% of the amount is refundable.
								</Trans>
								<br />
								<Trans ns="privateEvents">
									prices do not include the local tax charge of 10%.
								</Trans>
								<br />
								<Trans ns="privateEvents">
									prices do not include transfer fee.
									<br />
								</Trans>
							</p>
						</div>

						<h2>
							<Trans ns="privateEvents">event duration</Trans>.
						</h2>

						<div>
							<p className="right">
								<Trans ns="privateEvents">breakfast.</Trans>&emsp;&emsp;&emsp;
								2-2.5h.
								<br />
								<Trans ns="privateEvents">lunch / dinner.</Trans>
								&emsp;&emsp;&emsp; 3-4&nbsp;&nbsp;h.
							</p>
						</div>

						<div>
							{/* <p>
								<Trans ns="privateEvents">
									create your own <b>bossa sharing experience</b> by selecting
									your favorite dishes
								</Trans>.
							</p> */}
							<a href="#contact">
								<Trans ns="privateEvents">interested? contact us</Trans>.
							</a>
						</div>
					</Parallax>

					<StaticImage
						src={`../../../images/cafe/events/table.jpg`}
						alt={t("a table full of food ready to being served")}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="mix">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans>vive um mix</Trans>.
						</h2>

						<div>
							<p>
								<Trans>
									We are a <b>family owned and operated business</b>, inspired
									by the traits of some of the most iconic countries & what they
									have to offer.{" "}
									<b>Natural products and food made with love!</b> We aim to
									transmit simplicity, nature and joyfulness
								</Trans>
								.
							</p>
							<p>
								<Trans>
									We translate these values into our products and environments
									in order to offer our customers: “An Experience”. For this
									reason, we value craftsmanship, small producers and a family
									atmosphere
								</Trans>
								.
							</p>
						</div>
					</Parallax>

					<StaticImage
						src={`../../../images/cafe/events/tray.jpg`}
						alt={t("a tray with delicious food")}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="contact">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans>contact</Trans>.
						</h2>

						<div className="contactList">
							{contactList.map((detail) => {
								return (
									<div key={detail.name}>
										<span className="light">{detail.name}. </span>
										<div>
											{detail.internalLink && (
												<Link to={detail.internalLink}>{detail.text}</Link>
											)}
											{detail.link && <a href={detail.link}>{detail.text}</a>}
											{!detail.link && !detail.internalLink && detail.text}.
										</div>
									</div>
								);
							})}
						</div>
					</Parallax>

					<StaticImage
						src={`../../../images/cafe/events/friends.jpg`}
						alt={t("two friends laughing with cocktails in his hands")}
						className={"landingStripeImage"}
					/>
				</section>
			</div>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
